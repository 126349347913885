import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout/layout-home"
import TitledContentWrapper from "../components/layout/titled-content-wrapper"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: "50px",
    maxWidth: "1000px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
}))

const PrivacyPolicyPage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        privacyPolicyPage {
          Content
          Title
        }
      }
    }
  `)

  return (
    <Layout>
      <TitledContentWrapper title={data.strapi.privacyPolicyPage.Title}>
        <Typography variant="body1" className={classes.content}>
          {/* <ReactMarkdown source={data.strapi.privacyPolicyPage.Content} /> */}
          <p>
            This Privacy Policy explains Digic Pictures Kft. ("Digic")
            information-gathering and dissemination practices for our website:
            www.digicgroup.com (the "Site"). This Site is the official web site
            of Digic Pictures Ltd. The Site is geared to adults ages 18 and
            over. The Site is dedicated to providing information about Digic
            Pictures'&nbsp;&nbsp; professional services and references.
          </p>

          <p>
            Digic Pictures takes your privacy rights seriously and has posted
            this Privacy Policy to let our visitors know what information we
            collect from you, why we collect it and what we do with it. Digic's
            goal is to collect and use only that information that we think is
            necessary for our legitimate business interests, such as to better
            understand and serve you, to provide better service, and to improve
            our marketing that we believe may be of interest to you.
          </p>

          <p>Acceptance of These Terms</p>

          <p>
            This Privacy Policy is incorporated into and is subject to the Digic
            Pictures' Terms and Conditions of Use for the Site. If you do not
            agree to the terms of this Privacy Policy or the Terms and
            Conditions of Use for the Site, please do not provide us with any
            information and do not use the Digic Site or the Site's services.
            Your continued use of the Digic Site in light of changes to this
            Privacy Policy will signify your acceptance of the changes for
            information gathered after the date of the change.
          </p>

          <p>Does this Privacy Policy apply to you?</p>

          <p>
            This privacy policy covers Digic's information collection and use
            practices for anyone who uses the Site. Except as otherwise set
            forth in this Privacy Policy, Digic will not share your personally
            identifiable information with any third parties unless you give us
            permission to do so or except as required by law or to cooperate
            with law enforcement authorities.
          </p>

          <p>
            This Site, however, contains links to other websites, advertisers
            and other third parties. By clicking on any of these links, you are
            leaving the Site. A link provided on the Site does not constitute an
            endorsement of the content, viewpoint, accuracy, opinions, policies,
            products, services or accessibility of that linked web site. Digic
            has no control over the information collected from or about you by
            any third party after you have left the Site. This Privacy Policy
            does not cover the data collection and use practices of any third
            party linked to Digic. Our company assumes no liability and is not
            responsible for any practices of such third party web sites,
            including their privacy practices, content, or other activities.
            Once you link to another web site from the Site, you are subject to
            the terms and conditions of that web site, including, but not
            limited to, its Internet privacy policy.
          </p>

          <p>
            What information, including personally identifiable information,
            does Digic collect from and about you?
          </p>

          <p>
            In order to provide you with our services, there are two methods
            whereby we collect information from you: passive collection of
            technical information and information you voluntarily provide to
            Digic, including personally identifiable information.
          </p>

          <p>Passive Collection of Technical Information.</p>

          <p>
            Digic automatically collects certain technical information from each
            visitor, including your IP address, the type of browser you use to
            surf the Internet, your computer's operating system, and the web
            site from which you accessed the Site. Your IP address tells us both
            your Internet Service Provider (ISP) and the general geographic
            location from which you are connecting. We may also use your IP
            address to track how long you stayed on the Site and what pages you
            visited. This information is used internally by Digic to help us
            provide a good web experience to our visitors, including providing
            proper formatting for our web pages and analyzing which aspects of
            the site are most effective.
          </p>

          <p>
            Digic's use and disclosure of the information you provide us,
            including personally identifiable information is set forth below.
          </p>

          <p>
            If you provide us with your email address, Digic will send you email
            notices of upcoming Site events. When you provide your email address
            to the Site, you will have the opportunity to "opt-out" of receiving
            such email messages. Additionally, the email messages will provide a
            method whereby you may opt-out of receiving future emails from
            Digic.
          </p>

          <p>
            How can you change information submitted to the Site or opt out of
            the Site's activities?
          </p>

          <p>
            You may edit the registration information that is associated with
            your username and email address by following the procedures outlined
            on the Site.
          </p>

          <p>
            As&nbsp;mentioned above, you may opt-out of receiving emails from
            the Site when you submit your email address and by following the
            procedures outlined in each email.
          </p>

          <p>
            What kinds of security procedures are in place to protect the loss,
            misuse or alteration of information under Digic's control?
          </p>

          <p>
            Digic uses industry standard security measures to protect the loss,
            misuse and alteration of the information under our control,
            including encryption and a secure server and other security
            measures. Nonetheless, due to the inherent dangers of the Internet,
            we cannot guarantee the protection of such information against loss,
            misuse, or alteration.
          </p>

          <p>A special notice to parents of children under the age of 13.</p>

          <p>
            The Site is a general audience web site and is intended to be used
            by adults interested in our company. It is not intended for children
            and Digic does not want to collect any personally identifiable
            information for users who are under the age of 18. The safety of
            children is very important to Digic, and Digic recommends that
            parents review this Privacy Policy and understand its terms, so that
            this Site is used only by persons age 18 and over.
          </p>

          <p>Modifications to this privacy policy.</p>

          <p>
            Digic reserves the right, at our discretion, to change, modify, add
            or remove portions of this Privacy Policy at any time to comply with
            any applicable state or federal regulations, to utilize new
            technologies or to improve your Digic experience, so please check
            back periodically to review our Privacy Policy. If Digic changes any
            portion of this Privacy Policy, the change will be posted in this
            document so that you will always know what information we gather,
            how we might use that information, and to whom we will disclose it.
            Digic will not change the use and dissemination practices related to
            information obtained under a prior version of this Privacy Policy
            without your prior consent. Please be sure to check this page
            periodically for changes.
          </p>

          <p>
            This Privacy Policy was last changed and posted on our Site on
            August 17, 2010.
          </p>
        </Typography>
      </TitledContentWrapper>
    </Layout>
  )
}

export default PrivacyPolicyPage
